import { render, staticRenderFns } from "./FolderDown.vue?vue&type=template&id=33de684e&"
import script from "./FolderDown.vue?vue&type=script&lang=js&"
export * from "./FolderDown.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./FolderDown.vue?vue&type=custom&index=0&blockType=svg&width=24&height=24&viewBox=0%200%2024%2024&fill=none&xmlns=http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg"
if (typeof block0 === 'function') block0(component)

export default component.exports