import Crud from '@/store/classes/crud'
import ApiHelper from '@/helpers/ApiHelper'
import { WORKFLOW_SERVER, WORKFLOW_SERVER_API_KEY } from '@/servers'

const crud = new Crud({ module: 'fioko_data_collection', host: WORKFLOW_SERVER, api_key: WORKFLOW_SERVER_API_KEY })

export default {
    namespaced: true,
    state: {
        ...crud.state,
        filterRules: {
            name: (value) => ({ like: value })
        },
        filter: {
            name: null
        },
    },
    mutations: {
        ...crud.mutations
    },
    actions: {
        ...crud.actions,

        import (context, data) {
            return ApiHelper.post(`${WORKFLOW_SERVER}/api/fioko-data-collection/import`, data, {}, { api_key: WORKFLOW_SERVER_API_KEY })
        },
        
        combineReports(context, {reportIds, dataCollectionId}) {
            return ApiHelper.get(
                `${WORKFLOW_SERVER}/api/fioko-data-collection/generate-xls`,
                { 'report_ids': reportIds, 'data_collection_id': dataCollectionId },
                { api_key: WORKFLOW_SERVER_API_KEY }
            );
        },

        downloadGeneratedFile(context, dataCollectionId) {
            return ApiHelper.post(
                `${WORKFLOW_SERVER}/api/fioko-data-collection/download`,
                { 'data_collection_id': dataCollectionId },
                {},
                { api_key: WORKFLOW_SERVER_API_KEY, responseType: 'blob' }
            );
        }
    }
}