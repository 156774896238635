<template>
    <path
        :fill="color"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.8 2.00001C8.8 1.55818 8.44182 1.20001 8 1.20001C7.55817 1.20001 7.2 1.55818 7.2 2.00001V3.20001H5C3.4536 3.20001 2.2 4.45361 2.2 6.00001V10V20C2.2 21.5464 3.4536 22.8 5 22.8H19C20.5464 22.8 21.8 21.5464 21.8 20V10V6.00001C21.8 4.45361 20.5464 3.20001 19 3.20001H16.8V2.00001C16.8 1.55818 16.4418 1.20001 16 1.20001C15.5582 1.20001 15.2 1.55818 15.2 2.00001V3.20001H8.8V2.00001ZM20.2 9.20001V6.00001C20.2 5.33727 19.6627 4.80001 19 4.80001H16.8V6.00001C16.8 6.44184 16.4418 6.80001 16 6.80001C15.5582 6.80001 15.2 6.44184 15.2 6.00001V4.80001H8.8V6.00001C8.8 6.44184 8.44182 6.80001 8 6.80001C7.55817 6.80001 7.2 6.44184 7.2 6.00001V4.80001H5C4.33726 4.80001 3.8 5.33727 3.8 6.00001V9.20001H20.2ZM3.8 10.8H20.2V20C20.2 20.6628 19.6627 21.2 19 21.2H5C4.33726 21.2 3.8 20.6628 3.8 20V10.8ZM7.2 14C7.2 13.5582 7.55817 13.2 8 13.2H8.01C8.45183 13.2 8.81 13.5582 8.81 14C8.81 14.4418 8.45183 14.8 8.01 14.8H8C7.55817 14.8 7.2 14.4418 7.2 14ZM12 13.2C11.5582 13.2 11.2 13.5582 11.2 14C11.2 14.4418 11.5582 14.8 12 14.8H12.01C12.4518 14.8 12.81 14.4418 12.81 14C12.81 13.5582 12.4518 13.2 12.01 13.2H12ZM15.2 14C15.2 13.5582 15.5582 13.2 16 13.2H16.01C16.4518 13.2 16.81 13.5582 16.81 14C16.81 14.4418 16.4518 14.8 16.01 14.8H16C15.5582 14.8 15.2 14.4418 15.2 14ZM8 17.2C7.55817 17.2 7.2 17.5582 7.2 18C7.2 18.4418 7.55817 18.8 8 18.8H8.01C8.45183 18.8 8.81 18.4418 8.81 18C8.81 17.5582 8.45183 17.2 8.01 17.2H8ZM11.2 18C11.2 17.5582 11.5582 17.2 12 17.2H12.01C12.4518 17.2 12.81 17.5582 12.81 18C12.81 18.4418 12.4518 18.8 12.01 18.8H12C11.5582 18.8 11.2 18.4418 11.2 18ZM16 17.2C15.5582 17.2 15.2 17.5582 15.2 18C15.2 18.4418 15.5582 18.8 16 18.8H16.01C16.4518 18.8 16.81 18.4418 16.81 18C16.81 17.5582 16.4518 17.2 16.01 17.2H16Z"
    />
</template>

<script>
import IconMixin from '@/mixins/IconMixin'

export default {
    mixins: [IconMixin]
}
</script>