import ApiHelper from '@/helpers/ApiHelper.js'
import { USER_SERVER, USER_SERVER_API_KEY, CODE_SERVER, CODE_SERVER_API_KEY } from '@/servers'
// import Crud from '@/store/classes/crud'
// const crud = new Crud({
//     module: 'license'
// })
// const md5 = require('js-md5')

export default {
    namespaced: true,
    state: {
        items: [],
        loading: false
    },
    mutations: {
        setItems (state, value) {
            state.items = value;
        },
        setLoading (state, value) {
            state.loading = value;
        }
    },
    actions: {
        async list({ state, commit, dispatch }) {
            
            if (state.loading) { return }
            
            commit('setLoading', true);

            try {
                const { data, success, error } = await ApiHelper.get(USER_SERVER + '/api/license', {
                    fields: '*'
                }, {
                    api_key: USER_SERVER_API_KEY
                });

                if (!success) { throw new Error(error) }
                
                commit('setItems', _.get(data, 'items', []));
                dispatch('app/updateIsOnlineValue', true, { root: true })
            } catch (e) {
                console.error(e)
                dispatch('app/updateIsOnlineValue', false, { root: true })
            } finally {
                commit('setLoading', false);
            }
        },
        subjects() {
            return ApiHelper.get(CODE_SERVER + '/api/license-type/subjects', {}, {
                api_key: CODE_SERVER_API_KEY
            });
        },
        // list: crud.actions.list,
        checkCode(context, data) {
            return ApiHelper.get(USER_SERVER + '/api/license/check', data, {
                api_key: USER_SERVER_API_KEY
            });
        },
        summary() {
            return ApiHelper.get(USER_SERVER + '/api/license/summary', {}, {
                api_key: USER_SERVER_API_KEY
            });
        },
        activate(context, params) {
            return ApiHelper.post(USER_SERVER + '/api/license/activate', {
                owner_id: _.get(context, 'rootState.user.profile.id', '0'),
                owner_login: _.get(context, 'rootState.user.profile.login', params.account?.login),
                ...params.activation
            }, 
            {
                hash: params.hash
            },
            {
                api_key: USER_SERVER_API_KEY
            });
        },
        async sync(context) {
            // Only authorized allow sync
            if(!context.rootGetters['user/isAuthorized'])
                return ;
            if(!context.rootGetters['user/isAdmin'])
                return ;

            return ApiHelper.post(USER_SERVER + '/api/license/sync', {}, {},
                {
                    api_key: USER_SERVER_API_KEY,
                    axiosOptions: {
                        timeout: 2000
                    }
                }
            );
        }
    }
}