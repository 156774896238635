<template>
    <path
        d="M22.0003 12C22.0003 12.0948 21.9734 12.1875 21.9227 12.2675C21.872 12.3476 21.7996 12.4115 21.714 12.452L3.71402 20.952C3.62412 20.9956 3.52318 21.0112 3.42434 20.9966C3.32549 20.9821 3.2333 20.9381 3.15974 20.8705C3.08619 20.8029 3.03468 20.7147 3.0119 20.6174C2.98913 20.5202 2.99614 20.4183 3.03202 20.325L5.87402 12.698C6.0417 12.2478 6.0417 11.7523 5.87402 11.302L3.03102 3.67504C2.99495 3.58168 2.98785 3.47962 3.01064 3.38216C3.03343 3.28471 3.08505 3.19638 3.15878 3.1287C3.23251 3.06102 3.32492 3.01712 3.42396 3.00273C3.523 2.98834 3.62409 3.00413 3.71402 3.04804L21.714 11.548C21.7996 11.5885 21.872 11.6525 21.9227 11.7325C21.9734 11.8126 22.0003 11.9053 22.0003 12ZM22.0003 12H6.00002"
        :stroke="color"
        stroke-width="1.6"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
</template>

<script>
import IconMixin from '@/mixins/IconMixin'

export default {
    mixins: [IconMixin]
}
</script>