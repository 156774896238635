import Crud from '@/store/classes/crud'
import ApiHelper from '@/helpers/ApiHelper.js'
import { USER_SERVER, USER_SERVER_API_KEY } from '@/servers'

const crud = new Crud({
    module: 'group'
})

export default {
    namespaced: true,
    state: {
        ...crud.state,
        filterRules: {
            name: (value) => ({ like: value })
        },
        filter: {
            active: null,
            name: null
        },
        loading: false,
        items: []
    },
    mutations: {
        ...crud.mutations,
        setLoading (state, value) {
            state.loading = value;
        },
        setItems (state, value) {
            state.items = value;
        }
    },
    actions: {
        ...crud.actions,
        async fetch({ state, commit, dispatch }, options) {
            
            if (state.loading) { return }
            
            commit('setLoading', true)

            try {
                const { data, success, error } = await ApiHelper.get(
                    USER_SERVER + '/api/group',
                    options,
                    { api_key: USER_SERVER_API_KEY }
                )

                if (!success) { throw new Error(error) }
                
                commit('setItems', _.get(data, 'items', []))
                dispatch('app/updateIsOnlineValue', true, { root: true })
            } catch (e) {
                console.error(e)
                dispatch('app/updateIsOnlineValue', false, { root: true })
            } finally {
                commit('setLoading', false)
            }
        }
    }
}