<template>
    <path
        :fill="color"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 2C9.23858 2 7 4.23858 7 7V10H6.5C5.67157 10 5 10.6716 5 11.5V15C5 18.866 8.13401 22 12 22C15.866 22 19 18.866 19 15V11.5C19 10.6716 18.3284 10 17.5 10H17V7C17 4.23858 14.7614 2 12 2ZM15 10V7C15 5.34315 13.6569 4 12 4C10.3431 4 9 5.34315 9 7V10H15ZM12 13.2C12.4418 13.2 12.8 13.5582 12.8 14V16C12.8 16.4418 12.4418 16.8 12 16.8C11.5582 16.8 11.2 16.4418 11.2 16V14C11.2 13.5582 11.5582 13.2 12 13.2Z"
    />
</template>

<script>
import IconMixin from '@/mixins/IconMixin'

export default {
    mixins: [IconMixin]
}
</script>