<template>
    <v-card class="elevation-0">
        <!-- Body -->
        <v-card-text>
            <v-form>
                <error-alert
                  v-if="!_.isNil(error)"
                  :message="error"
                />
                
                <v-stepper v-model="step" vertical class="elevation-0">
                    <v-stepper-step :complete="step > 1" step="1" :color="$const.color.primary">
                        Ввод ключа
                        <div v-if="step > 1">
                            <small>{{ form.code }}, {{ form.license_type }}</small>
                        </div>
                    </v-stepper-step>
                    <v-stepper-content step="1">
                        <!-- Code check form -->
                        <div v-if="step === 1">
                            <v-text-field
                                v-model="$v.form.code.$model"
                                :error-messages="getErrors('form.code')"
                                label="Ключ"
                                name="code"
                                prepend-icon="mdi-key"
                                type="text"
                                placeholder=" "
                                autofocus="autofocus"
                                :color="$const.color.primary"
                            ></v-text-field>

                            <v-divider class="my-4" />

                            <v-btn
                                outlined
                                height="40px"
                                :loading="waiting"
                                class="button-stylized"
                                @click.prevent.stop="submitStep"
                            >
                                {{ buttonLabel }}
                            </v-btn>
                        </div>
                       <!-- END Code check form -->
                    </v-stepper-content>
                    <v-stepper-step :complete="step > 2" step="2" :color="$const.color.primary">
                        Активация ключа
                    </v-stepper-step>
                    <v-stepper-content step="2">
                        <!-- Code activation form -->
                        <div v-if="step === 2">
                            <v-text-field 
                                v-model="$v.form.activation.name.$model"
                                :error-messages="getErrors('form.activation.name')"
                                label="ФИО" 
                                name="name" 
                                type="text" 
                                placeholder=" "
                                autofocus="autofocus" 
                                :color="$const.color.primary"
                            ></v-text-field>

                            <v-text-field 
                                v-model="$v.form.activation.company.$model"
                                :error-messages="getErrors('form.activation.company')"
                                label="Организация" 
                                name="company" 
                                type="text" 
                                placeholder=" "
                                autofocus="autofocus" 
                                :color="$const.color.primary"
                            ></v-text-field>

                            <v-text-field 
                                v-model="$v.form.activation.email.$model"
                                :error-messages="getErrors('form.activation.email')"
                                label="E-mail" 
                                name="email" 
                                type="text" 
                                placeholder=" "
                                autofocus="autofocus" 
                                :color="$const.color.primary"
                                ></v-text-field>

                            <v-text-field 
                                v-model="$v.form.activation.address.$model"
                                :error-messages="getErrors('form.activation.address')"
                                label="Адрес" 
                                name="address" 
                                type="text" 
                                placeholder=" "
                                autofocus="autofocus" 
                                :color="$const.color.primary"
                                ></v-text-field>

                            <v-divider class="my-4" />

                            <v-btn
                                outlined
                                height="40px"
                                :loading="waiting"
                                class="button-stylized"
                                @click.prevent.stop="submitStep"
                            >
                                {{ buttonLabel }}
                            </v-btn>
                        </div>
                        <!-- END Code activation form -->
                    </v-stepper-content>
                    <v-stepper-step v-if="newAccount" :complete="step > 3" step="3" :color="$const.color.primary">
                        Создание аккаунта
                    </v-stepper-step>
                    <v-stepper-content v-if="newAccount" step="3">
                        <!-- Account form -->
                        <div v-if="step === 3">
                            <v-text-field 
                                v-model="$v.form.account.login.$model"
                                :error-messages="getErrors('form.account.login')"
                                autocomplete="new-email"
                                label="Логин" 
                                name="login" 
                                type="text" 
                                placeholder=" "
                                autofocus="autofocus" 
                                :color="$const.color.primary"
                            ></v-text-field>

                            <v-text-field 
                                v-model="$v.form.account.new_password.$model"
                                :error-messages="getErrors('form.account.new_password')"
                                autocomplete="new-password"
                                label="Пароль" 
                                name="new_password" 
                                type="password" 
                                placeholder=" " 
                                :color="$const.color.primary"
                            ></v-text-field>

                            <v-text-field 
                                v-model="$v.form.account.confirm_password.$model"
                                :error-messages="getErrors('form.account.confirm_password', {
                                    compare: 'Пароль не совпадает с подтверждением'
                                })"
                                label="Подтверждение пароля" 
                                name="confirm_password" 
                                type="password" 
                                placeholder=" " 
                                color="purple darken-2"
                            ></v-text-field>

                            <v-text-field 
                                v-model="$v.form.account.name.$model"
                                :error-messages="getErrors('form.account.name')"
                                label="ФИО" 
                                name="name" 
                                type="text" 
                                placeholder=" "
                                :color="$const.color.primary"
                            ></v-text-field>

                            <v-divider class="my-4" />

                            <v-btn
                                outlined
                                height="40px"
                                :loading="waiting"
                                class="button-stylized"
                                @click.prevent.stop="submitStep"
                            >
                                {{ buttonLabel }}
                            </v-btn>
                        </div>
                        <!-- END Account form -->
                    </v-stepper-content>
                </v-stepper>
            </v-form>
        </v-card-text>
    </v-card>
</template>
<script>
import { errorMixin } from '@/mixins/formMixin'
import { validationMixin } from 'vuelidate'
import { required, email, minLength } from 'vuelidate/lib/validators'
import { mapState } from 'vuex'
import ErrorAlert from '@/components/template/ErrorAlert.vue'

const md5 = require('js-md5');

export default {
    name: 'RegisterForm',
    mixins: [errorMixin, validationMixin],
    components: { ErrorAlert },
    props: {
        newAccount: {
            type: Boolean,
            default: false
        },
        nextButtonLabel: {
            type: String,
            default: 'Далее'
        },
        lastButtonLabel: {
            type: String,
            default: 'Завершить'
        }
    },
    data() {
        return {
            error: null,
            step: 1,
            form: {
                // Step 1
                code: '',
                license_type: '',
                // Step 2
                activation: {
                    name: '',
                    company: '',
                    email: '',
                    address: '',
                },
                // Step 3
                account: {
                    login: '',
                    new_password: '',
                    confirm_password: '',
                    name: ''
                }
            },
            waiting: false
        }
    },
    computed: {
        ...mapState('app', ['online']),
        lastStep() {
            return this.newAccount === true ? 3 : 2;
        },
        buttonLabel() {
            return this.step === this.lastStep ? this.lastButtonLabel : this.nextButtonLabel;
        }
    },
    validations() {
        const PASSWORD_MIN_LENGTH = 6;
        
        switch(this.step)
        {
            case 1:
                return {
                    form: {
                        code: { required }
                    }
                }

            case 2:
                return {
                    form: {
                        activation: {
                            name: { 
                                required,
                                minLength: minLength(2)
                            },
                            company: { 
                                required,
                                minLength: minLength(2)
                            },
                            email: { 
                                required,
                                email
                            },
                            address: { 
                                required,
                                minLength: minLength(2)
                            }
                        }
                    }
                }

            case 3:
                return {
                    form: {
                        account: {
                            login: {
                                required,
                                email
                            },
                            new_password: {
                                required,
                                minLength: minLength(PASSWORD_MIN_LENGTH)
                            },
                            confirm_password: {
                                required,
                                minLength: minLength(PASSWORD_MIN_LENGTH),
                                compare: function(value) {
                                    return this.form.account.new_password === value;  
                                },
                                
                            },
                            name: {
                                required,
                                minLength: minLength(2)
                            }
                        }
                    }
                }

            default: 
                return {}
        }
    },
    methods: {
        async submitStep()
        {
            if (this.validate()) {
                this.waiting = true;
                this.serverErrors = null;
                this.error = null;

                let success = null;

                switch(this.step)
                {
                    case 1:
                        success = await this.checkCode();
                    break;
                    case 2:
                        success = await this.activateCode();
                    break;
                    case 3:
                        success = await this.register();
                    break;
                    default:
                        return ;
                }

                if(success)
                {
                    this.serverErrors = null;

                    if(this.step < 3)
                        this.step++;
                }

                this.waiting = false;
                return ;
            }
        },
        async checkCode() {
            try {

                const { success, error, data } = await this.$store.dispatch('license/checkCode', {
                    hash: md5(this.form.code?.trim()),
                    newAccount: this.newAccount ? 1 : 0
                });

                if (!error && !this.online) {
                    this.serverErrors = this.createConnectionError()
                    return
                }

                if(success)
                {
                    this.form.license_type = _.get(data, 'license_type.name', '');
                }
                else
                    _.set(this, 'serverErrors.code', [error]);

                return success;
            } catch (e) {
                console.error(e)
            }
        },
        async activateCode() {
            if (!this.newAccount) {
                const activated = await this.activate()
                if (activated)
                    this.$emit('success', 1)
                else {
                    if (this.error)
                        return
                    const defaultErrorMessage = 'Неверно заполнены данные формы'
                    if (!this.serverErrors)
                        this.error = defaultErrorMessage
                    else
                        this.error = this.serverErrors[Object.keys(this.serverErrors)[0]]?.[0] || defaultErrorMessage
                }
            }
            else
                return true
        },
        async register() {
            const { success: s1, error: e1 } = await this.$store.dispatch('user/register', this.form.account)

            if (!e1 && !this.online) {
                this.serverErrors = this.createConnectionError()
                return false
            }

            if (!s1) {
                this.serverErrors = e1
                return false
            }
            const activated = await this.activate()

            if (!activated)
                return false

            const loginPayload = { login: this.form.account.login, password: this.form.account.new_password }
            const { success: s2, data, error: e2 } = await this.$store.dispatch('user/login', loginPayload)

            if (!s2) {
                this.serverErrors = e2
                return false
            }

            this.$store.dispatch('user/setSession', data)

            if (s2)
                this.$emit('success', 1);

            return s2;
        },
        async activate()
        {
            try {
            
                const { success, error } = await this.$store.dispatch('license/activate', {
                    hash: md5(this.form.code?.trim()),
                    ...this.form
                });

                if (!error && !this.online) {
                    this.serverErrors = this.createConnectionError()
                    return
                }

                if(!success)
                    this.serverErrors = error;

                return success;
            } catch (e) {
                console.error(e)
            }
        },
        createConnectionError () {
            return { summary: 'Отсутствует подключение к серверу' }
        }
    }

}
</script>
<style scoped lang="scss">
.login-form {
    .login-form__show-password-submit-group {
        @media screen and (max-width: 667px) {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
        }
    }
}
</style>