import Vue from 'vue'
import ApiHelper from '@/helpers/ApiHelper'
import {
    CODE_SERVER, CODE_SERVER_API_KEY,
    JOB_SERVER, JOB_EXTERNAL_SERVER_API_KEY,
    JOB_EXTERNAL_SERVER, JOB_SERVER_API_KEY
} from '@/servers'

const currentVersion = require('@/../package.json').version;

export default {
    namespaced: true,
    state: {
        appUpdates: null,
        online: true,
        serverInfo: null,
        subjects: null
    },
    mutations: {
        SET_APP_UPDATES (state, value) {
            Vue.set(state, 'appUpdates', value);
        },
        SET_ONLINE_STATUS (state, value) {
            Vue.set(state, 'online', !!value);
        },
        SET_SERVER_INFO (state, value) {
            Vue.set(state, 'serverInfo', value);
        },
        SET_SUBJECTS (state, value) {
            Vue.set(state, 'subjects', value);
        }
    },
    actions: {
        updateIsOnlineValue ({ commit }, value) {
            commit('SET_ONLINE_STATUS', value)
        },
        async checkAppUpdates({commit}) {
            const { data } = await ApiHelper.get(`${CODE_SERVER}/api/version/check`, {
                version: currentVersion,
                platform: navigator.platform?.toLocaleLowerCase() === 'win32' ? 'windows' : 'linux'
            }, {
                api_key: CODE_SERVER_API_KEY
            });

            commit('SET_APP_UPDATES', data);
        },
        async fetchSubjects ({ commit }) {
            const { data } = await ApiHelper.get(`${JOB_SERVER}/api/site/subjects`, {}, { api_key: JOB_SERVER_API_KEY })
            if (!data)
                throw new Error('Subject wasn\'t loaded.')
            
            commit('SET_SUBJECTS', data || [])
        },
        async fetchContentInstalledVersion(context, payload) {
            return ApiHelper.get(`${JOB_SERVER}/api/content-kit/installed-version`, payload, {
                api_key: JOB_SERVER_API_KEY
            });
        },
        async checkContentNewVersion(context, payload) {
            return ApiHelper.get(`${JOB_EXTERNAL_SERVER}/api/content-kit/check-new-version`, payload, {
                api_key: JOB_EXTERNAL_SERVER_API_KEY
            });
        },
        async downloadContentKit(context, payload) {
            return ApiHelper.request(
                    `${JOB_EXTERNAL_SERVER}/api/content-kit/download`,
                    'get',
                    {},
                    payload,
                    { api_key: JOB_EXTERNAL_SERVER_API_KEY, responseType: 'blob' }
                );
        },
        async installContentKit(context, payload) {
            return ApiHelper.post(`${JOB_SERVER}/api/content-kit/install`, payload, {}, { api_key: JOB_SERVER_API_KEY });
        },
        waitUntilRequiredDataLoaded ({ state, rootGetters }) {
            return new Promise(resolve => {
                const intervalId = setInterval(() => {
                    if (state.subjects?.length && rootGetters['calendar_event/subjects']?.length) {
                        clearInterval(intervalId)
                        resolve(true)
                    }
                }, 100)
            })
        }

    },
    getters: {
        appUpdatesAvailable(state) {
            return !_.isNil(state.appUpdates);
        }
    }
}
