<template>
    <path
        :fill="color"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.02005 2.02005C4.54515 1.49495 5.25735 1.19995 5.99995 1.19995H14H15C15.2121 1.19995 15.4156 1.28424 15.5656 1.43427L20.5656 6.43427C20.7157 6.58429 20.7999 6.78778 20.7999 6.99995V7.99819L20.8 7.99995L20.7999 8.00172V20C20.7999 20.7426 20.5049 21.4547 19.9798 21.9798C19.4547 22.5049 18.7426 22.7999 18 22.7999H5.99995C5.25734 22.7999 4.54515 22.505 4.02005 21.9798C3.49495 21.4547 3.19995 20.7426 3.19995 20V3.99995C3.19995 3.25735 3.49495 2.54515 4.02005 2.02005ZM5.99995 2.79995H13.2V5.99995C13.2 6.74256 13.495 7.45475 14.0201 7.97985C14.5452 8.50495 15.2573 8.79995 16 8.79995H19.2V20C19.2 20.3182 19.0735 20.6234 18.8485 20.8485C18.6234 21.0735 18.3182 21.2 18 21.2H5.99995C5.68169 21.2 5.37647 21.0735 5.15142 20.8485C4.92638 20.6234 4.79995 20.3182 4.79995 20V3.99995C4.79995 3.68169 4.92638 3.37647 5.15142 3.15142C5.37647 2.92638 5.68169 2.79995 5.99995 2.79995ZM16 7.19995H19.0686L14.8 2.93132V5.99995C14.8 6.31821 14.9264 6.62344 15.1514 6.84848C15.3765 7.07352 15.6817 7.19995 16 7.19995ZM8.79995 16C8.79995 15.5581 8.44178 15.2 7.99995 15.2C7.55812 15.2 7.19995 15.5581 7.19995 16V18C7.19995 18.4418 7.55812 18.8 7.99995 18.8C8.44178 18.8 8.79995 18.4418 8.79995 18V16ZM12 13.2C12.4418 13.2 12.8 13.5581 12.8 14V18C12.8 18.4418 12.4418 18.8 12 18.8C11.5581 18.8 11.2 18.4418 11.2 18V14C11.2 13.5581 11.5581 13.2 12 13.2ZM16.8 12C16.8 11.5581 16.4418 11.2 16 11.2C15.5581 11.2 15.2 11.5581 15.2 12V18C15.2 18.4418 15.5581 18.8 16 18.8C16.4418 18.8 16.8 18.4418 16.8 18V12Z"
    />
</template>

<script>
import IconMixin from '@/mixins/IconMixin'

export default {
    mixins: [IconMixin]
}
</script>