<template>
    <path d="M16 14L12 10L8 14" :fill="color"/>
</template>

<script>
import IconMixin from '@/mixins/IconMixin'

export default {
    mixins: [IconMixin]
}
</script>