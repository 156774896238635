<template>
    <g>
        <path
            d="M14.7 3H9.30002C8.80297 3 8.40002 3.40294 8.40002 3.9V5.7C8.40002 6.19706 8.80297 6.6 9.30002 6.6H14.7C15.1971 6.6 15.6 6.19706 15.6 5.7V3.9C15.6 3.40294 15.1971 3 14.7 3Z"
            :stroke="color"
            stroke-width="1.6"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M15.6 4.80005H17.4C17.8774 4.80005 18.3353 4.98969 18.6728 5.32726C19.0104 5.66482 19.2 6.12266 19.2 6.60005V19.2C19.2 19.6774 19.0104 20.1353 18.6728 20.4728C18.3353 20.8104 17.8774 21 17.4 21H6.60005C6.12266 21 5.66482 20.8104 5.32726 20.4728C4.98969 20.1353 4.80005 19.6774 4.80005 19.2V6.60005C4.80005 6.12266 4.98969 5.66482 5.32726 5.32726C5.66482 4.98969 6.12266 4.80005 6.60005 4.80005H8.40005"
            :stroke="color"
            stroke-width="1.6"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </g>
</template>

<script>
import IconMixin from '@/mixins/IconMixin'

export default {
    mixins: [IconMixin]
}
</script>