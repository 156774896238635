<template>
    <svg
        :width="width"
        :height="height"
        :viewBox="`0 0 ${width} ${height}`"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <component
            :is="iconName"
            :color="color"
        />
    </svg>
</template>

<script>
const icons = {}
const r = require.context('./content', true, /\.vue$/)
const componentNames = r.keys()

for (const key of componentNames) {
    
    const name = key.replace('./', '').replace('.vue', '')
    icons[name] = require(`./content/${name}.vue`)?.default
}

export default {
    components: { ...icons },
    props: {
        width: { type: [String, Number], default: 24 },
        height: { type: [String, Number], default: 24 },
        color: { type: [String, Number], default: 'black' },
        name: { type: String, required: true }
    },
    computed: {
        iconName () {
            const name = this.name.split('-').map((str) => [str[0].toUpperCase(), ...str.slice(1)].join('')).join('')
            // return require(`./content/${name}.vue`)?.default
            return name
        }
    }
}
</script>