<template>
    <path
        d="M4.99995 3.80001C4.68169 3.80001 4.37647 3.92644 4.15142 4.15148C3.92638 4.37653 3.79995 4.68175 3.79995 5.00001V19C3.79995 19.3183 3.92638 19.6235 4.15142 19.8485C4.37647 20.0736 4.68169 20.2 4.99995 20.2H8.99995C9.44178 20.2 9.79995 20.5582 9.79995 21C9.79995 21.4418 9.44178 21.8 8.99995 21.8H4.99995C4.25734 21.8 3.54515 21.505 3.02005 20.9799C2.49495 20.4548 2.19995 19.7426 2.19995 19V5.00001C2.19995 4.25741 2.49495 3.54522 3.02005 3.02011C3.54515 2.49501 4.25735 2.20001 4.99995 2.20001H8.99995C9.44178 2.20001 9.79995 2.55818 9.79995 3.00001C9.79995 3.44184 9.44178 3.80001 8.99995 3.80001H4.99995ZM15.4343 6.43433C15.7467 6.12191 16.2532 6.12191 16.5656 6.43433L21.5647 11.4333C21.5679 11.4366 21.5711 11.4398 21.5743 11.4431C21.7247 11.5982 21.8 11.7991 21.8 12C21.8 12.1093 21.778 12.2136 21.7383 12.3085C21.7008 12.3984 21.6461 12.4829 21.5743 12.5569C21.5711 12.5602 21.5679 12.5634 21.5647 12.5667L16.5656 17.5657C16.2532 17.8781 15.7467 17.8781 15.4343 17.5657C15.1218 17.2533 15.1218 16.7467 15.4343 16.4343L19.0686 12.8H8.99995C8.55812 12.8 8.19995 12.4418 8.19995 12C8.19995 11.5582 8.55812 11.2 8.99995 11.2H19.0686L15.4343 7.5657C15.1218 7.25328 15.1218 6.74675 15.4343 6.43433Z"
        fill-rule="evenodd"
        clip-rule="evenodd"
        :fill="color"
    />
</template>

<script>
import IconMixin from '@/mixins/IconMixin'

export default {
    mixins: [IconMixin]
}
</script>