export default {
    methods: {
        async retrieveOrLoad ({ module, action, forceLoad = false, fields = '*' } = {}) {

            if (!forceLoad && this.$store.state[module].items?.length) { return this.$store.state[module].items }

            const actionKey = `${module}/${action}`
            let justLoaded = false

            if (this.$store.state[module]?.loading) {

                await new Promise(resolve => {
                    const intervalId = setInterval(() => {
                        if (this.$store.state[module]?.loading === false) {
                            clearInterval(intervalId)
                            resolve()
                        }
                    }, 250)
                })
                justLoaded = true
            }
            if (forceLoad || !justLoaded)
                await this.$store.dispatch(actionKey, { pagination: 0, fields })
            
            return this.$store.state[module].items
        }
    }
}