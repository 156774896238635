import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import _ from 'lodash'

Object.defineProperty(Vue.prototype, '_', { value: _ });

// Constants
import constants from './constants'
Object.defineProperty(Vue.prototype, '$const', { value: constants });
// Moment.js
import moment from 'moment'
moment.locale('ru');
Object.defineProperty(Vue.prototype, '$moment', { value: moment });
// CSS styles
import '@/assets/scss/main.scss'
import '@mdi/font/scss/materialdesignicons.scss';

Vue.config.productionTip = false

// App init function
const init = async () => {
    // Restore state from local storage
    await store.dispatch('user/restoreSession');
    // Sync licenses
    await store.dispatch('license/sync');
    // Init vue app
    const app = new Vue({
        router,
        store,
        vuetify,
        render: h => h(App)
    }).$mount('#app')
    store.$app = app;
}

// Init application
init();