<template>
    <v-alert
        dense
        :color="$const.color.lightRed"
    >
        <div class="d-flex align-center">
            <icon name="warning" :color="$const.color.red" class="mr-4" />
            <div class="d-flex flex-column">
                <span class="color-red fw-500 fs-18">Ошибочка вышла</span>
                <span class="color-red fw-400 fs-12">
                    <slot>{{ message }}</slot>
                </span>
            </div>
        </div>
    </v-alert>
</template>

<script>
import Icon from '@/components/icons/Index.vue'

export default {
    components: { Icon },
    props: {
        message: { type: String, default: null }
    }
}
</script>