<template>
    <path d="M8 10L12 14L16 10" :fill="color"/>
</template>

<script>
import IconMixin from '@/mixins/IconMixin'

export default {
    mixins: [IconMixin]
}
</script>