<template>
    <path
        :fill="color"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.4911 1.36853C12.2022 1.14384 11.7977 1.14384 11.5088 1.36853L2.5088 8.36853C2.31393 8.5201 2.19995 8.75314 2.19995 9.00001V20C2.19995 20.7426 2.49495 21.4548 3.02005 21.9799C3.54515 22.505 4.25734 22.8 4.99995 22.8H8.99819C8.99877 22.8 8.99936 22.8 8.99995 22.8C9.00054 22.8 9.00113 22.8 9.00172 22.8H14.9982C14.9988 22.8 14.9994 22.8 15 22.8C15.0005 22.8 15.0011 22.8 15.0017 22.8H19C19.7426 22.8 20.4547 22.505 20.9798 21.9799C21.5049 21.4548 21.7999 20.7426 21.7999 20V9.00001C21.7999 8.75314 21.686 8.5201 21.4911 8.36853L12.4911 1.36853ZM15.8 21.2H19C19.3182 21.2 19.6234 21.0736 19.8485 20.8485C20.0735 20.6235 20.2 20.3183 20.2 20V9.39128L12 3.0135L3.79995 9.39128V20C3.79995 20.3183 3.92638 20.6235 4.15142 20.8485C4.37647 21.0736 4.68169 21.2 4.99995 21.2H8.19995V12C8.19995 11.5582 8.55812 11.2 8.99995 11.2H15C15.4418 11.2 15.8 11.5582 15.8 12V21.2ZM9.79995 21.2H14.2V12.8H9.79995V21.2Z"
    />
</template>

<script>
import IconMixin from '@/mixins/IconMixin'

export default {
    mixins: [IconMixin]
}
</script>