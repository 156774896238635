<template>
    <path
        :fill="color"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 1.2C9.2574 1.2 8.54521 1.495 8.02011 2.0201C7.49501 2.5452 7.20001 3.25739 7.20001 4V5.2H4.00001C2.45361 5.2 1.20001 6.4536 1.20001 8V12.9874C1.19989 12.9953 1.19989 13.0032 1.20001 13.0112V18C1.20001 19.5464 2.45361 20.8 4.00001 20.8H20C21.5464 20.8 22.8 19.5464 22.8 18V13.0112C22.8001 13.0033 22.8001 12.9953 22.8 12.9873V8C22.8 6.4536 21.5464 5.2 20 5.2H16.8V4C16.8 3.25739 16.505 2.5452 15.9799 2.0201C15.4548 1.495 14.7426 1.2 14 1.2H10ZM15.2 5.2V4C15.2 3.68174 15.0736 3.37651 14.8485 3.15147C14.6235 2.92642 14.3183 2.8 14 2.8H10C9.68175 2.8 9.37652 2.92642 9.15148 3.15147C8.92644 3.37651 8.80001 3.68174 8.80001 4V5.2H15.2ZM21.2 12.5632V8C21.2 7.33726 20.6628 6.8 20 6.8H16H8.00001H4.00001C3.33727 6.8 2.80001 7.33726 2.80001 8V12.5632C5.556 14.2869 8.74365 15.2033 12 15.2033C15.2564 15.2033 18.444 14.2869 21.2 12.5632ZM21.2 14.4202C18.3926 15.9793 15.2272 16.8033 12 16.8033C8.77284 16.8033 5.60747 15.9793 2.80001 14.4202V18C2.80001 18.6627 3.33727 19.2 4.00001 19.2H20C20.6628 19.2 21.2 18.6627 21.2 18V14.4202ZM12 11.2C11.5582 11.2 11.2 11.5582 11.2 12C11.2 12.4418 11.5582 12.8 12 12.8H12.01C12.4518 12.8 12.81 12.4418 12.81 12C12.81 11.5582 12.4518 11.2 12.01 11.2H12Z"
    />
</template>

<script>
import IconMixin from '@/mixins/IconMixin'

export default {
    mixins: [IconMixin]
}
</script>