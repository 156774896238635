import ApiHelper from '@/helpers/ApiHelper'
import Auth from '@/store/classes/auth'
import Crud from '@/store/classes/crud'
import { USER_SERVER } from '@/servers'

const auth = new Auth()
const crud = new Crud({ module: 'user' })
const roleRules = {
    app_update: {
        view: ['admin'],
        edit: ['admin']
    },
    print_app: {
        view: ['admin'],
        edit: ['admin']
    },
    update_admin_password: {
        view: ['admin_school'],
        edit: ['admin_school']
    },
    user: {
        view: ['admin'],
        edit: ['admin']
    },
    license: {
        view: ['admin'],
        edit: ['admin']
    },
    group: {
        view: ['admin'],
        edit: ['admin']
    },
    fioko_data_collection: {
        view: ['admin_vpr', 'admin_school'],
        edit: ['admin_vpr']
    },
    fioko_report: {
        view: ['admin_vpr', 'admin_school'],
        edit: ['admin_vpr']
    },
    performance: {
        view: ['admin_vpr', 'admin_school'],
        edit: []
    },
    calendar: {
        view: ['admin_vpr', 'admin_school'],
        edit: ['admin_vpr']
    }
}

export default {
    namespaced: true,
    state: {
        ...auth.state,
        ...crud.state,
        loading: {
            refresh_token: false
        },
        filter: {
            active: null,
            role: null,
            name: null,
            login: null,
        },
        filterRules: {
            name: (value) => ({ like: value })
        },
        roles: [
            {
                value: 'admin',
                text: 'Системный Администратор',
                priority: 0
            },
            {
                value: 'admin_vpr',
                text: 'Администратор ВПР',
                priority: 1
            },
            {
                value: 'admin_school',
                text: 'Администратор ОО',
                priority: 2
            },
            {
                value: 'teacher',
                text: 'Педагог',
                priority: 3
            },
            {
                value: 'student',
                text: 'Учащийся',
                priority: 4
            }
        ]
    },
    mutations: {
        ...auth.mutations,
        ...crud.mutations
    },
    actions: {
        ...auth.actions,
        ...crud.actions,
        async upload(context, data) {
            return ApiHelper.post(`${USER_SERVER}/api/user/import`, data);
        },
        checkAdminRegistration () {
            return ApiHelper.get(`${USER_SERVER}/api/user/check-admin-registration`)
        },
        waitUntilRefreshToken ({ state }) {
            return new Promise(resolve => {
                
                const intervalId = setInterval(() => {

                    if (!state.loading.refresh_token) {
                        clearInterval(intervalId)
                        resolve()
                    }
                })
            })
        }
    },
    getters: {
        ...auth.getters,
        userCan (state) {
            return (section, action) => {
                return roleRules[section]?.[action]?.includes(state.profile.role) 
            }
        }
    }
}