<template>
    <path
        :fill="color"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.8 4C3.8 3.88954 3.88954 3.8 4 3.8H9C9.11045 3.8 9.2 3.88954 9.2 4V9C9.2 9.11045 9.11045 9.2 9 9.2H4C3.88954 9.2 3.8 9.11045 3.8 9V4ZM4 2.2C3.00588 2.2 2.2 3.00588 2.2 4V9C2.2 9.99411 3.00588 10.8 4 10.8H9C9.99411 10.8 10.8 9.99411 10.8 9V4C10.8 3.00588 9.99411 2.2 9 2.2H4ZM14.8 4C14.8 3.88954 14.8895 3.8 15 3.8H20C20.1105 3.8 20.2 3.88954 20.2 4V9C20.2 9.11045 20.1105 9.2 20 9.2H15C14.8895 9.2 14.8 9.11045 14.8 9V4ZM15 2.2C14.0059 2.2 13.2 3.00588 13.2 4V9C13.2 9.99411 14.0059 10.8 15 10.8H20C20.9941 10.8 21.8 9.99411 21.8 9V4C21.8 3.00588 20.9941 2.2 20 2.2H15ZM15 14.8C14.8895 14.8 14.8 14.8895 14.8 15V20C14.8 20.1105 14.8895 20.2 15 20.2H20C20.1105 20.2 20.2 20.1105 20.2 20V15C20.2 14.8895 20.1105 14.8 20 14.8H15ZM13.2 15C13.2 14.0059 14.0059 13.2 15 13.2H20C20.9941 13.2 21.8 14.0059 21.8 15V20C21.8 20.9941 20.9941 21.8 20 21.8H15C14.0059 21.8 13.2 20.9941 13.2 20V15ZM3.8 15C3.8 14.8895 3.88954 14.8 4 14.8H9C9.11045 14.8 9.2 14.8895 9.2 15V20C9.2 20.1105 9.11045 20.2 9 20.2H4C3.88954 20.2 3.8 20.1105 3.8 20V15ZM4 13.2C3.00588 13.2 2.2 14.0059 2.2 15V20C2.2 20.9941 3.00588 21.8 4 21.8H9C9.99411 21.8 10.8 20.9941 10.8 20V15C10.8 14.0059 9.99411 13.2 9 13.2H4Z"
    />
</template>

<script>
import IconMixin from '@/mixins/IconMixin'

export default {
    mixins: [IconMixin]
}
</script>