export default {
    color: {
        applicationBackground: 'rgba(246, 245, 250, 1)',
        primary: 'rgba(34, 145, 255, 1)',
        grey: 'rgba(0, 0, 0, 0.12)',
        extraLightGrey: 'rgb(235, 235, 235)',
        fontGrey: 'rgba(17, 17, 17, 0.4)',
        fontDefault: 'rgb(17, 17, 17)',
        lightRed: 'rgba(255, 0, 0, 0.12)',
        red: 'red'
    },
    icons: {
        arrowKey: require('@/assets/img/icons/arrow-key.svg'),
        enterKey: require('@/assets/img/icons/enter-key.svg'),
    }
}