<template>
    <path
        :fill="color"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.80001 7.00001C5.80001 5.2327 7.2327 3.80001 9.00001 3.80001C10.7673 3.80001 12.2 5.2327 12.2 7.00001C12.2 8.76732 10.7673 10.2 9.00001 10.2C7.2327 10.2 5.80001 8.76732 5.80001 7.00001ZM9.00001 2.20001C6.34905 2.20001 4.20001 4.34905 4.20001 7.00001C4.20001 9.65098 6.34905 11.8 9.00001 11.8C11.651 11.8 13.8 9.65098 13.8 7.00001C13.8 4.34905 11.651 2.20001 9.00001 2.20001ZM6.00001 14.2C4.72697 14.2 3.50607 14.7057 2.6059 15.6059C1.70573 16.5061 1.20001 17.727 1.20001 19V21C1.20001 21.4418 1.55818 21.8 2.00001 21.8C2.44184 21.8 2.80001 21.4418 2.80001 21V19C2.80001 18.1513 3.13715 17.3374 3.73727 16.7373C4.33739 16.1372 5.15132 15.8 6.00001 15.8H12C12.8487 15.8 13.6626 16.1372 14.2628 16.7373C14.8629 17.3374 15.2 18.1513 15.2 19V21C15.2 21.4418 15.5582 21.8 16 21.8C16.4418 21.8 16.8 21.4418 16.8 21V19C16.8 17.727 16.2943 16.5061 15.3941 15.6059C14.494 14.7057 13.2731 14.2 12 14.2H6.00001ZM18.2254 14.93C18.3359 14.5022 18.7722 14.245 19.2 14.3554C20.2298 14.6213 21.1421 15.2216 21.7936 16.0622C22.4452 16.9027 22.7992 17.9359 22.8 18.9994V19V21C22.8 21.4418 22.4418 21.8 22 21.8C21.5582 21.8 21.2 21.4418 21.2 21V19.0006V19.0003C21.1994 18.2914 20.9634 17.6028 20.5291 17.0425C20.0947 16.4821 19.4865 16.0819 18.8 15.9046C18.3722 15.7942 18.115 15.3578 18.2254 14.93ZM16.1984 2.35502C15.7704 2.24543 15.3346 2.50357 15.225 2.93159C15.1154 3.35961 15.3736 3.79543 15.8016 3.90502C16.4899 4.08126 17.1 4.48158 17.5357 5.04287C17.9714 5.60415 18.2079 6.29448 18.2079 7.00502C18.2079 7.71555 17.9714 8.40588 17.5357 8.96717C17.1 9.52846 16.4899 9.92878 15.8016 10.105C15.3736 10.2146 15.1154 10.6504 15.225 11.0784C15.3346 11.5065 15.7704 11.7646 16.1984 11.655C17.2309 11.3907 18.1461 10.7902 18.7996 9.94824C19.4531 9.10631 19.8079 8.07082 19.8079 7.00502C19.8079 5.93921 19.4531 4.90372 18.7996 4.06179C18.1461 3.21986 17.2309 2.61938 16.1984 2.35502Z"
    />
</template>

<script>
import IconMixin from '@/mixins/IconMixin'

export default {
    mixins: [IconMixin]
}
</script>