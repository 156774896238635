<template>
    <v-card class="elevation-12">
        <!-- Body -->
        <v-card-text>
            <v-form @submit="login">
                <error-alert
                  v-if="!_.isNil(summaryError)"
                  :message="summaryError"
                />

                <v-label><span class="label">Логин</span></v-label>
                <v-text-field 
                    v-model="$v.form.login.$model"
                    :error-messages="getErrors('form.login')"
                    name="login" 
                    prepend-icon="mdi-account" 
                    type="text" 
                    autofocus="autofocus" 
                    :color="$const.color.primary"
                    @keydown.enter="login"
                />

                <v-label><span class="label">Пароль</span></v-label>
                <v-text-field
                    v-model="$v.form.password.$model"
                    :error-messages="getErrors('form.password')"
                    id="password" 
                    name="password" 
                    prepend-icon="mdi-lock" 
                    type="password" 
                    placeholder=" " 
                    :color="$const.color.primary"
                    @keydown.enter="login"
                />
            </v-form>
        </v-card-text>
        <!-- Footer -->
        <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn
                outlined
                height="40px"
                :loading="waiting"
                class="button-stylized-blue"
                @click.prevent.stop="login"
            >
                Войти
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import { errorMixin } from '@/mixins/formMixin'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { mapState } from 'vuex'
import ErrorAlert from '@/components/template/ErrorAlert.vue'

export default {
    name: 'LoginForm',
    components: { ErrorAlert },
    mixins: [errorMixin, validationMixin],
    data () {
        return {
            form: {
                login: '',
                password: ''
            },
            waiting: false
        };
    },
    validations: {
        form: {
            login: {
                required
            },
            password: {
                required
            }
        }
    },
    computed: {
        ...mapState('app', ['online'])
    },
    methods: {
        async login() {
            this.waiting = true;
            this.serverErrors = null;

            if (!this.validate()) {
                this.waiting = false;
                return;
            }
            try {
                const { success, data, error } = await this.$store.dispatch('user/login', this.form);
                const isValidRole = success && ['admin', 'admin_vpr', 'admin_school'].includes(_.get(data, 'role', null));

                if (success && isValidRole) {
                    const profile = {}
                    Object.keys(data).forEach(key => {
                        if (['access_token', 'refresh_token'].includes(key)) { return }
                        profile[key] = data[key]
                    })
                    this.$store.dispatch('user/setSession', data);
                    this.$emit('success', 1);
                }
                else
                {
                    if (!this.online) {
                        this.serverErrors = { summary: 'Отсутствует подключение к серверу' }
                    }
                    else if (!isValidRole) {
                        this.serverErrors = { summary: 'Не верный логин или пароль' }
                    }
                    else if (error) {
                        this.serverErrors = error
                    } else {
                        this.serverErrors = { summary: 'Неизвестный ответ сервера' }
                    }
                }
            } catch (e) {
                console.error(e)
            }
            this.waiting = false
        }
    }

}
</script>
<style scoped lang="scss">
.label {
    display: block;
    font-size: 14px;
    margin-bottom: -10px;
    margin-left: 32px;
}
</style>