<template>
    <g>
        <path d="M12.6667 17V3" :stroke="color" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6.66675 11L12.6667 17L18.6667 11" :stroke="color" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M19.6667 21H5.66675" :stroke="color" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
</template>

<script>
import IconMixin from '@/mixins/IconMixin'

export default {
    mixins: [IconMixin]
}
</script>