<template>
    <path
        :fill="color"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.9 3C5.9 2.6134 6.2134 2.3 6.6 2.3H17.4C17.7866 2.3 18.1 2.6134 18.1 3V8.6H19.2C19.863 8.6 20.4989 8.86339 20.9678 9.33223C21.4366 9.80107 21.7 10.437 21.7 11.1V15.6C21.7 16.263 21.4366 16.8989 20.9678 17.3678C20.4989 17.8366 19.863 18.1 19.2 18.1H18.1V21C18.1 21.3866 17.7866 21.7 17.4 21.7H6.6C6.2134 21.7 5.9 21.3866 5.9 21V18.1H4.8C4.13696 18.1 3.50107 17.8366 3.03223 17.3678C2.56339 16.8989 2.3 16.263 2.3 15.6V11.1C2.3 10.437 2.56339 9.80107 3.03223 9.33223C3.50107 8.86339 4.13696 8.6 4.8 8.6H5.9V3ZM7.3 17.4V20.3H16.7V17.4V14.5H7.3V17.4ZM18.1 16.7V13.8C18.1 13.4134 17.7866 13.1 17.4 13.1H6.6C6.2134 13.1 5.9 13.4134 5.9 13.8V16.7H4.8C4.50826 16.7 4.22847 16.5841 4.02218 16.3778C3.81589 16.1715 3.7 15.8917 3.7 15.6V11.1C3.7 10.8083 3.81589 10.5285 4.02218 10.3222C4.22847 10.1159 4.50826 10 4.8 10H6.6H17.4H19.2C19.4917 10 19.7715 10.1159 19.9778 10.3222C20.1841 10.5285 20.3 10.8083 20.3 11.1V15.6C20.3 15.8917 20.1841 16.1715 19.9778 16.3778C19.7715 16.5841 19.4917 16.7 19.2 16.7H18.1ZM16.7 3.7V8.6H7.3V3.7H16.7Z"
    />
</template>

<script>
import IconMixin from '@/mixins/IconMixin'

export default {
    mixins: [IconMixin]
}
</script>