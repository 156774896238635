import ApiHelper from '@/helpers/ApiHelper'
import Crud from '@/store/classes/crud'
import { WORKFLOW_SERVER, WORKFLOW_SERVER_API_KEY } from '@/servers'

const crud = new Crud({ module: 'calendar_event', host: WORKFLOW_SERVER, api_key: WORKFLOW_SERVER_API_KEY })

export default {
    namespaced: true,
    state: {
        ...crud.state,
        loading: false,
        items: [],
        defaultItems: []
    },
    mutations: {
        ...crud.mutations,
        setLoading (state, value) {
            state.loading = value;
        },
        setItems (state, value) {
            state.items = value;
        },
        setDefaultItems (state, value) {
            state.defaultItems = value;
        }
    },
    actions: {
        ...crud.actions,
        async fetchDefaultList ({ commit }) {
            try {

                const { success, error, data } = await ApiHelper.get(
                    `${WORKFLOW_SERVER}/api/calendar-event/default-list`,
                    {},
                    { api_key: WORKFLOW_SERVER_API_KEY }
                )

                if (!success)
                    throw new Error(error)

                commit('setDefaultItems', data)
            } catch (e) {
                console.error(e)
            }
        },
        async list({ state, commit, dispatch }, options) {
            
            if (state.loading) { return }
            
            commit('setLoading', true)

            try {
                const { data, success, error } = await ApiHelper.get(
                    WORKFLOW_SERVER + '/api/calendar-event',
                    options,
                    { api_key: WORKFLOW_SERVER_API_KEY }
                )

                if (!success) { throw new Error(error) }
                
                commit('setItems', _.get(data, 'items', []))
                dispatch('app/updateIsOnlineValue', true, { root: true })
            } catch (e) {
                console.error(e)
                dispatch('app/updateIsOnlineValue', false, { root: true })
            } finally {
                commit('setLoading', false)
            }
        }
    },
    getters: {
        subjects (state) {
            const subjects = state.defaultItems?.map(item => item.subject) || []
            return Array.from(new Set(subjects)).map(value => ({ text: value, value }))
        }
    }
}